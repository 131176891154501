.App {
  text-align: center;
}


.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.switch {
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;
}

.switch input { 
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #2196F3;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

.Cursor{
  cursor: none;
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}





/* (A) LIST STYLES */
.slist {
  list-style: none;
  padding: 0;
  margin: 0;
}
.slist li {
  margin: 10px;
  padding: 15px;
  border: 1px solid #dfdfdf;
  background: #f5f5f5;
}

/* (B) DRAG-AND-DROP HINT */
.slist li.hint {
  border: 1px solid #ffc49a;
  background: #feffb4;
}
.slist li.active {
  border: 1px solid #ffa5a5;
  background: #ffe7e7;
}

/* (X) DOES NOT MATTER */
* {
  font-family: arial, sans-serif;
  box-sizing: border-box;
}



.image-checkbox {
	cursor: pointer;
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
	border: 4px solid transparent;
	margin-bottom: 0;
	outline: 0;
}

/* fix dropdown issue */
/* .css-b62m3t-container {
     z-index: 10;
} */
/* chat list */
.msg {
	display: grid;
	margin: 20px;
	grid-template-columns: repeat(5, 1fr);
	grid-template-rows: repeat(1, 1fr);
}
.content {
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
}
.message {
  border-radius: 20px;
  text-align: center;
  color: white;
  padding: 0.7rem;
  position: relative;
}

.theres {
  left: 70%;
  background-color: #00B2FF;
}
.msg_from .content {
	grid-area: 1 / 2 / 2 / 2;
	background: #DDA0DD;
	border-radius: 20px 20px 20px 0;
	text-align: left;
	align-self: center;
}
.msg_to .content {
	/* grid-area: 1 / 2 / 2 / 5; */
	background: #AFEEEE;
	border-radius: 20px 20px 0 20px;
	text-align: right;
	align-self: center;
}

.ours {
  right: 65%;
  background-color: #E9EBEE;
  color: black;
}

.App {
  margin-top: 10%;
}
/* chat show */
.card-chat {
  background: #fff;
  transition: .5s;
  border: 0;
  margin-bottom: 30px;
  border-radius: .55rem;
  position: relative;
  width: 100%;
  box-shadow: 0 1px 2px 0 rgb(0 0 0 / 10%);
}
.chat-app .people-list {
  width: 280px;
  position: absolute;
  left: 0;
  top: 0;
  padding: 20px;
  z-index: 7
}

.chat-app .chat {
  /* margin-left: 280px; */
  border-left: 1px solid #eaeaea
}

.people-list {
  -moz-transition: .5s;
  -o-transition: .5s;
  -webkit-transition: .5s;
  transition: .5s
}

.people-list .chat-list li {
  padding: 10px 15px;
  list-style: none;
  border-radius: 3px
}

.people-list .chat-list li:hover {
  background: #efefef;
  cursor: pointer
}

.people-list .chat-list li.active {
  background: #efefef
}

.people-list .chat-list li .name {
  font-size: 15px
}

.people-list .chat-list img {
  width: 45px;
  border-radius: 50%
}

.people-list img {
  float: left;
  border-radius: 50%
}

.people-list .about {
  float: left;
  padding-left: 8px
}

.people-list .status {
  color: #999;
  font-size: 13px
}

.chat .chat-header {
  padding: 15px 20px;
  border-bottom: 2px solid #f4f7f6
}

.chat .chat-header img {
  float: left;
  border-radius: 40px;
  width: 40px
}

.chat .chat-header .chat-about {
  float: left;
  padding-left: 10px
}

.chat .chat-history {
  padding: 20px;
  border-bottom: 2px solid #fff
}

.chat .chat-history ul {
  padding: 0
}

.chat .chat-history ul li {
  list-style: none;
  margin-bottom: 30px
}

.chat .chat-history ul li:last-child {
  margin-bottom: 0px
}

.chat .chat-history .message-data {
  margin-bottom: 15px
}

.chat .chat-history .message-data img {
  border-radius: 40px;
  width: 40px
}

.chat .chat-history .message-data-time {
  color: #434651;
  padding-left: 6px
}

.chat .chat-history .message {
  color: #444;
  padding: 18px 20px;
  line-height: 26px;
  font-size: 16px;
  border-radius: 7px;
  display: inline-block;
  position: relative
}

.chat .chat-history .message:after {
  bottom: 100%;
  left: 7%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #fff;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .my-message {
  background: #efefef
}

.chat .chat-history .my-message:after {
  bottom: 100%;
  left: 30px;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
  border-bottom-color: #efefef;
  border-width: 10px;
  margin-left: -10px
}

.chat .chat-history .other-message {
  background: #e8f1f3;
  text-align: right
}

.chat .chat-history .other-message:after {
  border-bottom-color: #e8f1f3;
  left: 93%
}

.chat .chat-message {
  padding: 20px
}

.online,
.offline,
.me {
  margin-right: 2px;
  font-size: 8px;
  vertical-align: middle
}

.online {
  color: #86c541
}

.offline {
  color: #e47297
}

.me {
  color: #1d8ecd
}

.float-right {
  float: right
}

.clearfix:after {
  visibility: hidden;
  display: block;
  font-size: 0;
  content: " ";
  clear: both;
  height: 0
}

@media only screen and (max-width: 767px) {
  .chat-app .people-list {
      height: 465px;
      width: 100%;
      overflow-x: auto;
      background: #fff;
      left: -400px;
      display: none
  }
  .chat-app .people-list.open {
      left: 0
  }
  .chat-app .chat {
      margin: 0
  }
  .chat-app .chat .chat-header {
      border-radius: 0.55rem 0.55rem 0 0
  }
  .chat-app .chat-history {
      height: 300px;
      overflow-x: auto
  }
}

@media only screen and (min-width: 768px) and (max-width: 992px) {
  .chat-app .chat-list {
      height: 650px;
      overflow-x: auto
  }
  .chat-app .chat-history {
      height: 600px;
      overflow-x: auto
  }
}

@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .chat-app .chat-list {
      height: 480px;
      overflow-x: auto
  }
  .chat-app .chat-history {
      height: calc(100vh - 350px);
      overflow-x: auto
  }
}



/* support msg */


.app-message-chats {
  padding: 75px 24px;
  overflow-y: auto;
  text-align: center;
  height: calc(153% - 132px);
  background-color: #fff;
}


.select_boxes_3{
  z-index: 3;
}
.select_boxes{
  z-index: 2;
}
.custom-file{
  z-index: 1;
}






/* dashboard baxes */

.box{
  background-color: #000;
  padding: 10px;
  border-radius: 10px;
}

.boxes_headings{
  font-size: 30px;
  font-weight: 600;
  color: #fff;
  margin-bottom: 20px;
}
.small_boxes_outer{
  /* display: flex;
  flex-direction: row;
  align-items: center; */
  /* justify-content: center; */
  margin: 10px;

}
.small_boxes{
  background-color: #212121;
  /* margin: 5px; */
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  max-width: 335px;
  width: 300px;
  margin-right: 10px;
}

.small_boxes h3{
  font-size: 20px;
  color: #fff;
}


.small_boxes p{
  font-size: 16px;
  color: #878787;
}

.dashboard_page .icon{
  color: #fff;
  font-size: 20px;
}


.donutchart{
  width: 200px !important;
  height: 200px !important;
  margin-left: 40px;
}

.donutchart-innertext{
  font-size: 50px !important;
  font-weight: 600 !important;
}

.donutchart-legend{
  display: none;
}
.print{
  height: 42px !important;
}

.bgchats{
  background-image:url("https://img.freepik.com/free-vector/abstract-background-with-squares_23-2148995948.jpg?w=1060&t=st=1674111575~exp=1674112175~hmac=66468c9ba1737472c493d149c5afc2205cf83c54c44c6d66a52e427de2263c5a");
  overflow-y: scroll;
  /* max-height: 495px;
  min-height: 398px; */
}


.form-control{
  border-radius: 9px;
}

.wb-trash {
  color: red;
}

.wb-edit{
  color: #05ec05;
}
/* Invoice table........................ */

@import 'https://fonts.googleapis.com/css?family=Open+Sans:600,700';

* {font-family: 'Open Sans', sans-serif;}

.rwd-table {
  margin: auto;
  min-width: 300px;
  max-width: 100%;
  border-collapse: collapse;
}

.rwd-table tr:first-child {
  border-top: none;
  background: #428bca;
  color: #fff;
}

.rwd-table tr {
  border-top: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  background-color: #f5f9fc;
}

.rwd-table tr:nth-child(odd):not(:first-child) {
  background-color: #ebf3f9;
}

.rwd-table th {
  display: none;
}

.rwd-table td {
  display: block;
}

.rwd-table td:first-child {
  margin-top: .5em;
}

.rwd-table td:last-child {
  margin-bottom: .5em;
}

.rwd-table td:before {
  content: attr(data-th) ": ";
  font-weight: bold;
  width: 120px;
  display: inline-block;
  color: #000;
}

.rwd-table th,
.rwd-table td {
  text-align: left;
}

.rwd-table {
  color: #333;
  border-radius: .4em;
  overflow: hidden;
}

.rwd-table tr {
  border-color: #bfbfbf;
}

.rwd-table th,
.rwd-table td {
  padding: .5em 1em;
}
@media screen and (max-width: 601px) {
  .rwd-table tr:nth-child(2) {
    border-top: none;
  }
}
@media screen and (min-width: 600px) {
  .rwd-table tr:hover:not(:first-child) {
    background-color: #d8e7f3;
  }
  .rwd-table td:before {
    display: none;
  }
  .rwd-table th,
  .rwd-table td {
    display: table-cell;
    padding: .25em .5em;
  }
  .rwd-table th:first-child,
  .rwd-table td:first-child {
    padding-left: 0;
  }
  .rwd-table th:last-child,
  .rwd-table td:last-child {
    padding-right: 0;
  }
  .rwd-table th,
  .rwd-table td {
    padding: 1em !important;
  }
}


/* THE END OF THE IMPORTANT STUFF */

/* Basic Styling */
/* body {
background: #4B79A1;
background: -webkit-linear-gradient(to left, #4B79A1 , #283E51);
background: linear-gradient(to left, #4B79A1 , #283E51);        
} */
/* h1 {
  text-align: center;
  font-size: 2.4em;
  color: #f2f2f2;
} */
/* .container {
  display: block;
  text-align: center;
} */
h3 {
  display: inline-block;
  position: relative;
  text-align: center;
  font-size: 1.5em;
  color: #cecece;
}
h3:before {
  content: "\25C0";
  position: absolute;
  left: -50px;
  -webkit-animation: leftRight 2s linear infinite;
  animation: leftRight 2s linear infinite;
}
h3:after {
  content: "\25b6";
  position: absolute;
  right: -50px;
  -webkit-animation: leftRight 2s linear infinite reverse;
  animation: leftRight 2s linear infinite reverse;
}
@-webkit-keyframes leftRight {
  0%    { -webkit-transform: translateX(0)}
  25%   { -webkit-transform: translateX(-10px)}
  75%   { -webkit-transform: translateX(10px)}
  100%  { -webkit-transform: translateX(0)}
}
@keyframes leftRight {
  0%    { transform: translateX(0)}
  25%   { transform: translateX(-10px)}
  75%   { transform: translateX(10px)}
  100%  { transform: translateX(0)}
}



/* Invoice......................... */
/* body{
  margin: 0;
  padding: 0;
  font: 400 .875rem 'Open Sans', sans-serif;
  color: #bcd0f7;
  background: #1A233A;
  position: relative;
  height: 100%;
} */
.invoice-container {
  padding: 1rem;
 
}
.invoice-container .invoice-header .invoice-logo {
  margin: 0.8rem 0 0 0;
  display: inline-block;
  font-size: 1.6rem;
  font-weight: 700;
  color: #bcd0f7;
}
.invoice-container .invoice-header .invoice-logo img {
  max-width: 130px;
}
.invoice-container .invoice-header address {
  font-size: 0.8rem;
  color: #8a99b5;
  margin: 0;
}
.invoice-container .invoice-details {
  margin: 1rem 0 0 0;
  padding: 1rem;
  line-height: 180%;
  background: #1a233a;
}
.invoice-container .invoice-details .invoice-num {
  text-align: right;
  font-size: 0.8rem;
}
.invoice-container .invoice-body {
  padding: 1rem 0 0 0;
}
.invoice-container .invoice-footer {
  text-align: center;
  font-size: 0.7rem;
  margin: 5px 0 0 0;
}

.invoice-status {
  text-align: center;
  padding: 1rem;
  background: #272e48;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
  margin-bottom: 1rem;
}
.invoice-status h2.status {
  margin: 0 0 0.8rem 0;
}
.invoice-status h5.status-title {
  margin: 0 0 0.8rem 0;
  color: #8a99b5;
}
.invoice-status p.status-type {
  margin: 0.5rem 0 0 0;
  padding: 0;
  line-height: 150%;
}
.invoice-status i {
  font-size: 1.5rem;
  margin: 0 0 1rem 0;
  display: inline-block;
  padding: 1rem;
  background: #1a233a;
  -webkit-border-radius: 50px;
  -moz-border-radius: 50px;
  border-radius: 50px;
}
.invoice-status .badge {
  text-transform: uppercase;
}

@media (max-width: 767px) {
  .invoice-container {
      padding: 1rem;
  }
}

.card1 {
  background: #272E48;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  border: 0;
  margin-bottom: 1rem;
}

.custom-table {
  border: 1px solid #2b3958;
}
.custom-table thead {
  background: #2f71c1;
}
.custom-table thead th {
  border: 0;
  color: #ffffff;
}
.custom-table > tbody tr:hover {
  background: #172033;
}
/* .custom-table > tbody tr:nth-of-type(even) {
  background-color: #1a243a;
} */
.custom-table > tbody td {
  border: 1px solid #2e3d5f;
  font-size: 14px;
}

.table {
  /* background: #1a243a; */
  /* color: #bcd0f7;
  font-size: .75rem; */
}
.text-success {
  color: #c0d64a !important;
}
.custom-actions-btns {
  margin: auto;
  display: flex;
  justify-content: flex-end;
}
.custom-actions-btns .btn {
  margin: .3rem 0 .3rem .3rem;
}